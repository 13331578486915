<template>
	<div>
		<nav class="top-nav fix-on-scroll">
			<div class="row">
				<div class="columns column12">
					<nuxt-link
						:to="{ path: localePath('/'), hash: route.name.startsWith('index') ? '#top' : '' }"
						class="logo"
					/>
					<div class="nav-buttons">
						<ul class="main-nav" :class="{ active: showMenu }">
							<li class="close-li">
								<div class="menu-close" @click="hideMenu">
									<font-awesome-icon :icon="`fal fa-times`" size="1x" />
								</div>
							</li>
							<li v-for="item in topmenu[locale]" :key="item.filename">
								<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
									{{ item.header }}
								</nuxt-link>
								<submenu
									v-if="item.submenu.length"
									:hide-all="!showMenu"
									:item="item"
									:subitems="item.submenu"
									@hide-menu="hideMenu"
								/>
							</li>
							<li v-if="page" class="mobile-lang-nav">
								<div v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
									<nuxt-link
										class="flag"
										:class="item.language"
										:to="`${item.filename}`"
										@click="hideMenu"
									>
										{{ $t(`${item.language}Long`) }}
									</nuxt-link>
								</div>
							</li>
						</ul>
					</div>
					<div class="nav-buttons buttons-border">
						<div v-if="page" class="language-navigation" @click="toggleLanguageMenu">
							<div class="lang-select">
								<span>{{ locale }}</span>
								<font-awesome-icon class="icon-margin-left" :icon="`far fa-chevron-down`" size="1x" />
								<div class="lang-wrapper" :class="{ active: showLangNav }">
									<div v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="`${item.filename}`"
											@click="hideMenu"
										>
											{{ $t(`${item.language}Short`) }}
										</nuxt-link>
									</div>
								</div>
							</div>
						</div>
						<div class="menu-button" @click.prevent="openMenu">
							<font-awesome-icon :icon="`fal fa-bars`" size="1x" />
						</div>
						<book-button class="button book-button reserve-now">
							<font-awesome-icon class="icon-margin" :icon="`fal fa-calendar-alt`" size="1x" />
							<span>{{ $t('bookNow') }}</span>
						</book-button>
					</div>
				</div>
			</div>
		</nav>
		<header v-if="!(page && page.headingImage)" class="no-slider" />
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();
const route = useRoute();

await fetchPage();

const showLangNav = ref(false);
const showMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const toggleLanguageMenu = () => {
	showLangNav.value = !showLangNav.value;
};

const openMenu = () => {
	showMenu.value = true;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};
</script>

<style lang="scss" scoped>
header.no-slider {
	height: 120px;
}

.locked .top-nav {
	z-index: 101;
}

.flag::before {
	content: '';
	width: 16px;
	position: relative;
	top: 1px;
	height: 16px;
	margin-right: 2px;
	display: inline-block;
}

.main-nav .flag::before {
	top: 3px;
	margin-right: 5px;
}

.flag.en::before {
	background: url('~/assets/images/en.png') no-repeat;
}

.flag.nl::before {
	background: url('~/assets/images/nl.png') no-repeat;
}

.flag.de::before {
	background: url('~/assets/images/de.png') no-repeat;
}

.flag.fr::before {
	background: url('~/assets/images/fr.png') no-repeat;
}

.flag.es::before {
	background: url('~/assets/images/es.png') no-repeat;
}

.menu-button {
	display: none;
	border: 2px solid #fff;
	color: #fff;
	font-size: 22px;
	height: 50px;
	padding: 0 13px;
	text-align: center;
	flex-flow: nowrap row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.noslider .top-nav {
	background: rgba(19 42 66 / 90%);
	backdrop-filter: blur(24px);
}

.top-nav {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 18;
	padding: 14px 0;
	transition: all 0.3s ease-in-out;

	&.fixed {
		background: rgba(19 42 66 / 90%);
		backdrop-filter: blur(24px);
	}

	.column12 {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		gap: 10px;
		align-items: center;
	}

	.row {
		overflow: visible;
	}

	.lang-nav {
		margin: 0 0 25px;

		img {
			width: 16px;
			float: left;
			margin: 0 5px 0 0;
		}

		a {
			width: 100%;
			float: left;
			color: $primary-text-color;
			text-decoration: none;
			margin: 4px 0;
			font-size: $secondary-font-size;
			text-transform: uppercase;

			&.active {
				color: $primary-color;
			}
		}
	}
}

.language-navigation:hover .lang-nav-sel,
.language-navigation:hover .fa-globe {
	color: $primary-color;
}

.lang-nav-sel {
	box-shadow: none;
	cursor: pointer;
	width: 30px;
	padding: 0 4px 0 0;
	color: #fff;
	border: none;
	text-transform: uppercase;
	font-size: 12px;
	font-family: sans-serif;
	appearance: none;
	background: url('~/assets/images/dropdown-language.png') right 1px center no-repeat;
	background-size: 7px;
	transition: color 0.3s ease-in-out;

	option {
		color: $primary-text-color;
	}

	&::-ms-expand {
		display: none;
	}
}

.lang-select {
	padding: 1px 15px;
	cursor: pointer;
	position: relative;
	color: #fff;

	i {
		font-size: 12px;
	}
}

.lang-wrapper {
	display: none;
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 10px 9px;
	text-align: center;
	background: $primary-background-color;
	box-shadow: 0 18px 18px rgb(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;

	&.active,
	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: space-between center;
		color: #000 !important;
		font-size: 13px;
		gap: 5px;
	}

	a {
		width: 100%;
		flex-direction: row;
	}

	img {
		width: 16px;
		height: 16px;
		margin: 2px 0 0;
	}
}

.language-navigation .fa-globe {
	margin: 0 -5px 0 5px;
	color: #fff;
	transition: color 0.3s ease-in-out;
}

.logo {
	background: url('~/assets/images/logo-hotel-emma-yellow-full.png') center center no-repeat;
	background-size: 128px;
	width: 128px;
	height: 91px;
	transition: all 0.3s ease-in-out;
}

.noslider .logo,
.fixed .logo {
	background: url('~/assets/images/logo-hotel-emma-yellow-small.png') center center no-repeat;
	background-size: 100px;
	width: 100px;
	height: 71px;
}

.main-nav {
	list-style: none;
	z-index: 4;
	text-align: right;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	.close-li,
	.mobile-lang-nav {
		display: none;
	}

	a {
		color: #fff;
		padding: 5px;
		text-decoration: none;

		&:hover,
		&.active {
			color: $primary-color;
		}
	}

	li {
		display: inline-block;
		position: relative;
		text-transform: uppercase;
		margin: 0 10px;
		letter-spacing: 1px;
		font-size: $secondary-font-size;
	}
}

.nav-buttons {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	gap: 10px;
	align-items: center;

	&.buttons-border {
		border-left: 1px solid #f2f2f2;
		padding-left: 10px;
		margin-left: 10px;
	}
}

.text-nav {
	z-index: 1;
	position: relative;
}

.book-button {
	color: #fff;
	background: $primary-color;
	line-height: 22px;
}

.main-nav li a {
	position: relative;
}

@media (max-width: 960px) {
	.menu-button {
		opacity: 1;
		z-index: 100;
		display: flex;
	}

	.logo {
		background: url('~/assets/images/logo-hotel-emma-yellow-small.png') center center no-repeat;
		background-size: 100px;
		width: 100px;
		height: 71px;
	}

	.top-nav {
		background: rgba(19 42 66 / 90%);
		backdrop-filter: blur(24px);
	}

	.nav-buttons.buttons-border {
		border: none;
		padding: 0;
		margin: 0;
	}

	.language-navigation,
	.lang-select {
		display: none;
	}

	.book-button {
		padding: 14px 25px;
	}

	.main-nav {
		position: fixed;
		flex-flow: column nowrap;
		justify-content: flex-start;
		background: rgba(19 42 66 / 90%);
		text-align: left;
		backdrop-filter: blur(24px);
		height: 100vh;
		padding: 20px 30px 50px;
		inset: 0;
		opacity: 0;
		z-index: 999;
		transition: all 0.5s ease-in-out;
		transform: translate(0, 100%) matrix(1, 0, 0, 1, 0, 0);

		&.active {
			transform: matrix(1, 0, 0, 1, 0, 0);
			opacity: 1;
		}

		.close-li {
			display: block;
			margin: 0 0 30px;
			font-size: 32px;
			cursor: pointer;
			color: #fff;
			text-align: right;
		}

		.mobile-lang-nav {
			display: flex;
			flex-flow: wrap column;
			margin: 30px 0 0;
			padding: 30px 0 0;
			border-top: 2px solid $primary-background-color;
			font-size: 14px;

			a {
				margin: 3px 0;
				width: 100%;
				text-transform: none;
				display: block;
			}

			img {
				max-width: 16px;
				margin: 0 8px 0 0;
			}
		}

		li {
			width: 100%;
			text-align: left;
			font-size: 22px;
			margin: 10px 0;
			text-transform: none;
		}
	}

	.text-nav .main-nav {
		display: none;
	}
}

@media (max-width: 600px) {
	.top-nav {
		padding: 18px 0;
	}

	.logo {
		font-size: 18px;
		line-height: 24px;

		h3 {
			font-size: 20px;
		}
	}

	.nav-buttons {
		gap: 3px;
	}

	.book-button {
		padding: 13px 20px 12px;
	}

	.menu-button {
		height: 47px;
	}
}
</style>
