<template>
	<div class="submenu-container">
		<span class="mobile-dropdown" :class="{ active }" @click="active = !active" />
		<div class="submenu" :class="{ active: hideAll ? resetState() : active }">
			<ul>
				<li v-for="item in subitems" :key="item.filename">
					<nuxt-link :to="`${item.filename}`" @click="hideMenu">
						{{ item.header }}
					</nuxt-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
defineProps({
	subitems: { type: Array, default: () => [] },
	hideAll: { type: Boolean, default: false },
});

const emit = defineEmits(['hide-menu']);

const active = ref(false);

const resetState = () => {
	active.value = false;
};

const hideMenu = () => {
	emit('hide-menu');
};
</script>

<style lang="scss" scoped>
.submenu-container {
	position: relative;
}

a {
	font-weight: 300;
	color: $primary-text-color;

	&.active,
	&:hover {
		color: $primary-color;
	}
}

.mobile-dropdown {
	display: none;
	width: 12px;
	height: 8px;
	background: url('~/assets/images/icon-form-dropdown.png') center center no-repeat;
	margin-bottom: 3px;

	&.active {
		transform: rotate(180deg);
	}
}

.submenu {
	ul {
		display: none;
		background: $primary-background-color;
		position: absolute;
		top: 5px;
		left: 0;
		text-align: left;
		padding: 7px;
		min-width: max-content;

		&.active {
			display: block;
		}

		li {
			padding: 5px 0;
			list-style: none;

			a {
				margin: 0 10px;
				text-decoration: none;
				font-size: 13px;
			}
		}
	}

	&.active ul {
		display: block;
	}
}

@media (max-width: 1200px) {
	.mobile-dropdown {
		display: inline-block;
	}

	.submenu-container {
		display: inline;
	}

	.submenu ul {
		position: static;
		background: transparent;
		text-align: center;
		text-transform: uppercase;
		padding: 15px;
	}
}
</style>
